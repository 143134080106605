import { Component, OnInit, ViewChild, ElementRef } from '@angular/core';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import { AuthService } from '../../services/auth.service';
import { LoadingController, ToastController, AlertController } from '@ionic/angular';

import { AngularFireAuth } from '@angular/fire/auth';
import { IonSlides} from '@ionic/angular';
import {Apollo, gql} from 'apollo-angular';
import { ActivatedRoute, Router} from '@angular/router';
import { NavParams } from '@ionic/angular';
//newly added
import { NavService } from '../../services/nav.service';
import { ModalController } from '@ionic/angular';
import { SetupModalPage } from '../setup-modal/setup-modal.page';
import { SetupPage } from '../setup/setup.page';
import { SetupHomeAddressPage } from '../setup-home-address/setup-home-address.page';
const now = new Date().toLocaleString("en-US", {timeZone: "America/New_York"});
const GET_TODAYS = gql`
  query GetToday {
    todays{
  todayID
  month{
    monthID
    month
  }
  
        day{
            day
            dayID
            date
            weekNumber
            month
            year
        }
          week{
            weekID
            week
            banner_placement{
              bannerUID
              bannerName
              
            }
            promos_placement{
              promoUID
              promotionName
            }
           
  }
  }
 }
`;

const createLoginLog = gql`

mutation createLoginLog($uid:ID, $dayID:ID, $weekID:ID, $yearID:ID ){
  createLogs(input:
    {
      action: Login
      uid:$uid
			dayID: $dayID                           
			weekID:$weekID
      yearID:$yearID                                                
			                         
      suppliers:{connect:{
        where:{
          node:{uid:$uid}
        }
      }}
      
    	
      days:{connect:{
         where:{
           node:{dayID:$dayID}
         }
       }}
       weeks:{connect:{
         where:{
           node:{weekID:$weekID}
         }
       }}
       years:{connect:{
         where:{
           node:{yearID:$yearID}
         }
       }}
       
       
    }
    
  ) 
  
  {
    logs{
    logID
    action
    date_create
    dayID
    weekID
    yearID
    suppliers{
      uid
      supplierID
      email
    }
     
      dayID
      weekID
      days{
        dayID
      }
      weeks{
        weekID
      }
      years{
        yearID
      }
     
        }
        }
        
    
  }
 
    


`;
@Component({
  selector: 'app-signup-pro',
  templateUrl: './signup-pro.page.html',
  styleUrls: ['./signup-pro.page.scss'],
})
export class SignupProPage implements OnInit {

  // uid = this.authService.getUid(); 
  @ViewChild('slides',{static:true}) slider: IonSlides;
  loading = true;
  registerForm: FormGroup;
  registerFormPro: FormGroup;
  registerFormHome: FormGroup;
  
  loginForm: FormGroup;
  segmentModel = "intro";

  //newly added for graphql ProV4
  
  segment = 'intro';

  error: any;
  uid:  any;
  data: any;
  Supplier: string;
  supplier: string
  uuid: any;
  id: string;
  email: string;
  password: string;
  username: string;
  name_first: string;
  name_last: string;
  company: string;
  supplierUID:string;
  dataReturned: string[];
  todays:any;
  today:any;
  dayID:any;
  weekID:any;
  yearID:any;
 
 
  //uid = this.authService.getUid(); 
 

  @ViewChild('flipcontainer', { static: false }) flipcontainer: ElementRef;

  constructor(private fb: FormBuilder, 
              private authService: AuthService,
              private afAuth: AngularFireAuth, 
              private navService: NavService,
              private loadingCtrl: LoadingController,
              private toastCtrl: ToastController, 
              private alertCtrl: AlertController, 
              private modalController: ModalController,
              private apollo: Apollo,
              private activatedRoute: ActivatedRoute,
              private router: Router) { }

              

  ngOnInit() 
  
  {
    
    this.dayID=this.dayID;
    this.weekID=this.weekID

    
    this.apollo
    .watchQuery({
      query: gql`
         query todays
 {
 todays{
  todayID
  month{
    monthID
    month
  }
  
        day{
            day
            dayID
            date
            weekNumber
            month
            year
        }
          week{
            weekID
            week
            banner_placement{
              bannerUID
              bannerName
              
            }
            promos_placement{
              promoUID
              promotionName
            }
           
  }
  }
 }
 
      `,
             //variables: {uid:this.uid} , 
        // variables:    {uid: this.afAuth.auth.currentUser.uid},
         
    })
    .valueChanges.subscribe((result: any) => {
      this.todays = result?.data?.todays;
      this.loading = result.loading;
      this.error = result.error;
    });
    console.log('todays',this.todays)
   

    
    this.registerForm = this.fb.group({
      email:      ['', [Validators.required, Validators.email]],
      password:   ['', [Validators.required, Validators.minLength(3)]],
      username:   [''],
      name_first: ['', [Validators.required, Validators.minLength(1)]],
      name_last:  ['', [Validators.required, Validators.minLength(1)]],
      company:    [''],
      name:       [''],
      uid:        [''],
      
   
      role: ['SELLER', Validators.required]
    });

    this.registerFormPro = this.fb.group({
      email:      ['', [Validators.required, Validators.email]],
      password:   ['', [Validators.required, Validators.minLength(6)]],
      username:   [''],
      name_first: [''],
      name_last:  [''],
      company:    [''],
      name:       [''],
      uid:        [''],
      
   
      role: ['BUYER'],
    });

    this.registerFormHome = this.fb.group({
      email:      ['', [Validators.required, Validators.email]],
      password:   ['', [Validators.required, Validators.minLength(6)]],
      username:   [''],
      name_first: [''],
      name_last:  [''],
      company:    [''],
      name:       [''],
      uid:        [''],
     
      
     
      //role: ['Seller', Validators.required]
      role: ['SELLER'],
    });

    

    this.loginForm = this.fb.group({
      email: ['', [Validators.required, Validators.email]],
      password: ['', [Validators.required, Validators.minLength(6)]]
    });
  }


  
  navigateByRole(role) {
    if (role == 'BUYER') {
      this.router.navigateByUrl('/buyer/home');
    } else if (role == 'SELLER') {
      this.router.navigateByUrl('/seller/entry');
    }
  }

  async login() {
    let loading = await this.loadingCtrl.create({
      message: 'Loading...'
    });
    await loading.present();

    this.authService.signIn(this.loginForm.value).subscribe(user => {
      loading.dismiss();
      console.log('after login: ', user);
      this.navigateByRole(user['role']);
    },
    async err => {
      loading.dismiss();

      let alert = await this.alertCtrl.create({
        header: 'Error',
        message: err.message,
        buttons: ['OK']
      });
      alert.present();
    })
  }

  async registerPro(uid,name_first, name_last) {
    let loading = await this.loadingCtrl.create({
      message: 'Loading Data ...',
      duration: 3000,
    });
    await loading.present();

    this.authService.signUp(this.registerFormPro.value).then(async res => {
      await loading.dismiss();

      

      let toast = await this.toastCtrl.create({
        duration: 4000,
        message: 'Successfully created new Account!'
      });
      
      toast.present();
      console.log('finished: ', res);
      
      this.authService.createSuppliers(this.afAuth.auth.currentUser.uid,this.afAuth.auth.currentUser.email, this.name_first, this.name_last) ;
      this.router.navigateByUrl('buyer/setup-modal');
      //this.openModalSetup();
      //this.openModalSetup()
      //this.authService.createBases(this.afAuth.auth.currentUser.uid) ;
      //this.authService.createBases(uid) ;
      //this.navigateByRole(this.registerForm.value['role']);
      

      
    }, async err => {
      await loading.dismiss();
      

      let alert = await this.alertCtrl.create({
        header: 'Error',
        message: err.message,
        buttons: ['OK']
      });
      alert.present();
    });
    
  }

  async registerHome(uid) {
   let loading = await this.loadingCtrl.create({
     message: 'Loading...'
   });
   await loading.present();

   this.authService.signUp(this.registerFormHome.value).then(async res => {
     await loading.dismiss();

     

     let toast = await this.toastCtrl.create({
       duration: 3000,
       position: 'middle',
       message: 'Successfully created New Home Account! '
     });
     
     toast.present();
     console.log('now finished: ', res);
     
     this.authService.createHomes(this.afAuth.auth.currentUser.uid,this.afAuth.auth.currentUser.email) ;
     //this.navigateByRole(this.registerForm.value['role']);
   
      this.router.navigateByUrl('seller/setup-home');
     

     
   }, async err => {
     await loading.dismiss();
     

     let alert = await this.alertCtrl.create({
       header: 'Error',
       message: err.message,
       buttons: ['OK']
     });
     alert.present();
   });
  }
    async openModalSetup(dayID,weekID) {
      
      const modal = await this.modalController.create({
        component: SetupModalPage,
        cssClass: 'my-modal-class',

        id:"set",
        componentProps: {
         // uid:this.uid,
          dayID:dayID,
          weekID:weekID
         
       
        }
      });
    
      modal.onDidDismiss().then((dataReturned) => {
        if (dataReturned !== null) {
          this.dataReturned = dataReturned.data;
          //alert('Modal Sent Data :'+ dataReturned);
        }
      });
    
      return await modal.present();
  }

  async openModalSetupAddress(dayID,weekID) {
    const modal = await this.modalController.create({
      component: SetupHomeAddressPage,
      id:"set",
      componentProps: {
        uid:this.uid,
        dayID:dayID,
        weekID:weekID
       
     
      }
    });
  
    modal.onDidDismiss().then((dataReturned) => {
      if (dataReturned !== null) {
        this.dataReturned = dataReturned.data;
        //alert('Modal Sent Data :'+ dataReturned);
      }
    });
  
    return await modal.present();
}
  async createSupplier(uid,email,name_first,name_last) {
   // this.authService.createSuppliers(this.registerFormPro.value) ;
    //this.authService.createSuppliers(this.afAuth.auth.currentUser.uid,this.afAuth.auth.currentUser.email) ;
  }
  async createHome(uid,email) {
    //dayID:this.registerForm.value.dayID,
     // weekID:this.registerForm.value.weekID,
    //this.authService.createSuppliers(this.registerForm.value) ;
    //this.authService.createSuppliers(this.afAuth.auth.currentUser.uid,this.afAuth.auth.currentUser.email) ;
  }
  async createBase(uid) {
    //this.authService.createSuppliers(this.registerForm.value) ;
    this.authService.createBases(this.afAuth.auth.currentUser.uid) ;
    let toast = await this.toastCtrl.create({
      duration: 3000,
      message: 'Successfully created new Base'
    });
  }
  

  

  toggleRegister() {
    this.flipcontainer.nativeElement.classList.toggle('flip');
  }
  async registerGraph() {
    

    this.authService.createSuppliers(this.uid,this.email,this.name_first,this.name_last) ;
    
     
  
      
     
  
    }
    segmentChanged(event){
      console.log(this.segmentModel);
      
      console.log(event);
  }
  professional(){
    this.segmentModel = "professional";
  }
  home(){
    this.segmentModel = "home";



  }
  createLoginLog( uid, dayID, weekID, yearID ) {
                
    this.apollo.mutate({
    mutation:  createLoginLog,
    variables: {
    
      
      uid:uid,
      dayID: dayID,                          
      weekID:weekID,
      yearID:yearID  
    
    
    
    },
  
    //newly added to test update function
  
   
  
  
  
    }).subscribe(({ data }) => {
    //console.log('id: ', id);
    
    console.log('data',data);
  
    }, (error) => {
    console.log('there was an big ass error sending the query', error);
    });
}
gotoLanding() {
  this.navService.gotoLanding();
 }
 closeModal():void{
   //this.modalController.dismiss();
   //this.modalController.dismiss(null, null, "signup");
   this.modalController.dismiss(null, null, "signuppro");
}
closeModalSingupHome():void{
 //this.modalController.dismiss();
 
 this.modalController.dismiss(null, null, "openModalSignupHome");
}
closeModalSingupPro():void{
  //this.modalController.dismiss();
  
  this.modalController.dismiss(null, null, "openModalSignupPro");
 }
}





